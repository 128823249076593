import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caret-up',
  templateUrl: './caret-up.component.html',
  styleUrls: ['./caret-up.component.scss']
})
export class CaretUpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
