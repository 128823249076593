import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-caret-down',
  templateUrl: './caret-down.component.html',
  styleUrls: ['./caret-down.component.scss']
})
export class CaretDownComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
