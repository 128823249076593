<style>
  .main-content,
  .section {
    margin: 0 auto;
    position: relative;
    display: block;
    box-sizing: border-box;
    content: contain;
    max-width: 575px;
    padding-left: 15px;
    padding-right: 15px;
  }

</style>

<div class="main-content">

  <div class="section">
    <h1 class="medium-title1">Store hours and locations</h1>
    <!-- regions -->
    <select #regionSelect (change)="onRegionChange(regionSelect.value)">
      <option>Select your region</option>
      <option *ngFor="let option of storeRegions">{{option.Name}}</option>
    </select>
    <!-- region stores -->
    <select #storeSelect (change)="onStoreChange(storeSelect.value)">
      <option>Select your store</option>
      <option *ngFor="let store of storesFromRegion">{{store.Name}}</option>
    </select>
    <!-- Filters -->
    <app-store-list-filter *ngIf="storeRegion" (filterEvent)="filterStores($event)"></app-store-list-filter>
  </div>

  <div class="section" *ngIf="storeRegion">
    <!-- tab list -->
    <div class="horizontal-align tablist">
      <button class="clear" [class.selected]="tabIndex===1" (click)="tabIndex=1">List</button>
      <button class="clear" [class.selected]="tabIndex===2" (click)="tabIndex=2">Map</button>
    </div>

    <div class="tablist-content">
      <div *ngIf="tabIndex===1">
        <p class="callout-heading">{{region + ' stores'}}</p>
        <app-store-details-list [stores]="_stores"></app-store-details-list>
      </div>
      <div *ngIf="tabIndex===2">
        <app-map #map [stores]="_stores" [selectedRegion]="_region"></app-map>
      </div>
    </div>

  </div>
</div>

<router-outlet></router-outlet>
