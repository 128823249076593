<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21" width="8" height="12">
  <defs>
    <style>
      .cls-1 {
        fill: none;
      }
      g {
        fill: currentColor;
      }
    </style>
  </defs>
  <g id="Group_1" data-name="Group 1" transform="translate(-254 -232)" fill="currentColor">
    <rect id="Rectangle_1" data-name="Rectangle 1" class="cls-1" width="21" height="21" transform="translate(254 232)"/>
    <path id="angle-down" fill="currentColor" stroke="currentColor"
    d="M107.741,10.99l-8.3,8.6a1.318,1.318,0,0,1-1.911,0,1.437,
    1.437,0,0,1,0-1.981L104.875,10,97.535,2.391a1.437,1.437,0,0,
    1,0-1.981,1.318,1.318,0,0,1,1.911,0l8.3,8.6a1.436,1.436,0,0,1,0,1.98Z"
    transform="translate(274.5 139.862) rotate(90)"/>
  </g>
</svg>
